import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../organisms/Layout/Layout';
import BubbleCtaSection from '../../organisms/BubbleCtaSection/BubbleCtaSection';
import { renderPageSections } from '../renderPageSections';
import useLocalize from '../../../hooks/useLocalize';

const ExecutivePage = ({
  data: rawData,
  location: { href },
  pageContext: { locale },
}) => {
  // unpublished pages return null data
  if (!rawData || rawData.sanityExecutivePage === null) {
    return null;
  }
  const data = useLocalize(rawData, locale);
  const executivePageProps = {
    metadata: { ...data.sanityExecutivePage._rawRoute, href },
    header: data.sanityExecutivePage._rawHeader,
    sections: data.sanityExecutivePage._rawSections,
    bubbleCtaSection: data.sanityExecutivePage._rawBubbleCtaSection,
  };
  return (
    <Layout
      locale={locale}
      sanityId={data.sanityExecutivePage._id}
      {...executivePageProps.header}
      {...executivePageProps.metadata}
    >
      {renderPageSections(executivePageProps.sections)}
      {executivePageProps.bubbleCtaSection && (
        <BubbleCtaSection {...executivePageProps.bubbleCtaSection} />
      )}
    </Layout>
  );
};

ExecutivePage.propTypes = {
  data: PropTypes.shape({ sanityExecutivePage: {} }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: {
    locale: PropTypes.string,
  },
};

ExecutivePage.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: { locale: 'us' },
};

export default ExecutivePage;

export const query = graphql`
  query($slug: String) {
    sanityExecutivePage(
      route: { enabled: { eq: true }, slug: { current: { eq: $slug } } }
    ) {
      _id
      _rawRoute(resolveReferences: { maxDepth: 100 })
      _rawHeader(resolveReferences: { maxDepth: 100 })
      _rawSections(resolveReferences: { maxDepth: 100 })
      _rawBubbleCtaSection(resolveReferences: { maxDepth: 100 })
    }
  }
`;
